import React, { useEffect, useState } from 'react'
import Box from '../../../components/box/Box'
import classes from './BottomNavBar.module.scss'
import Home from '../../../icons/Home'
import { useRouter } from 'next/router'
import theme from '../../../theme/Theme'
import Recipients from '../../../icons/Recipients'
import GiftBox from '../../../icons/GiftBox'
import User from '../../../icons/User'
import Typography from '../../../components/typography/Typography'
import SendMoney from '../../../icons/SendMoney'
import CurrencyExchange from '../../../icons/CurrencyExchange'
import ArrowUpLined from '../../../icons/ArrowUpLined'
import CloseIcon from '../../../icons/CloseIcon';
import { useSelector } from 'react-redux';
import MobileMenu from '../MobileMenu';
import { Analytics, ACTIONS } from '../../../../settings/analytics'
import Business from '../../../icons/Business'
import { getMoEngage, MoEngageTrackEvent } from '../../../../settings/moengage/moengage-service'
import moengage_events from '../../../../settings/moengage/moengage-event'

const BottomNavBar = ({
    handleLogout
}) => {
    const [selectedRoute, setSelectedRoute] = useState('dashboard');
    const [accountMenuOpen, setAccountMenuOpen] = useState(false);
    const router = useRouter();
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const { account_type } = useSelector((state: any) => state.business);

    const renderChildItems = ({ Icon: IconComponent, label, action }) => {
        const iconColor = selectedRoute === action ? theme.palette.primary.blue : theme.palette.input.body;
        return (
            <Box onClick={() => handleMenuClick(action)} className={classes['rb-item-container']}>
                <IconComponent width={24} height={24} color={iconColor} />
                <span style={{ color: iconColor }}>{label}</span>
            </Box>
        )
    }

    const toggleOverlay = () => {
        setIsOverlayVisible(!isOverlayVisible);
    };

    const handleMenuClick = (action: string) => {
        if (action === 'account-details') {
            const switchMenu = !accountMenuOpen;
            setAccountMenuOpen(!accountMenuOpen);

            if (switchMenu) {
                setSelectedRoute('account-details');
            } else {
                if (router.pathname) routeCheck(router.pathname);
            }
            return
        }
        setAccountMenuOpen(false);
        router.push(`/${action}`);
    }

    const routeCheck = (path: string) => {
        switch (path) {
            case '/dashboard':
                setSelectedRoute('dashboard')
                break;
            case '/business-account/dashboard':
                setSelectedRoute('business-account/dashboard')
                break;
            case '/recipients':
                setSelectedRoute('recipients')
                break;
            case '/rewards':
                setSelectedRoute('rewards')
                break;
        }
    }

    useEffect(() => {
        if (router.pathname) routeCheck(router.pathname);
    }, [router]);

    useEffect(() => {
        if (typeof document !== undefined) {
            const styles = `
            /* Fix crisp chat position if bottom bar is present */
            .crisp-client .cc-tlyw[data-full-view=true] .cc-kxkl .cc-nsge {
                bottom: 88px!important;
                right: 16px!important;
            }
            .crisp-client .cc-tlyw .cc-kxkl .cc-nsge {
                bottom: 88px!important;
                right: 16px!important;
            }
            #sidebar-inner-content {
                margin-bottom: 72px;
            }
        `;

            const styleTag = document.createElement('style');
            styleTag.appendChild(document.createTextNode(styles));
            document.head.appendChild(styleTag);

            // Clean up the injected styles when the bottom bar is unmounted
            return () => {
                document.head.removeChild(styleTag);
            };
        }
    }, []);

    const Overlay = () => {
        const handleMenuClick = (path: string) => {

            switch (path) {
                case '/send-money':
                    MoEngageTrackEvent(moengage_events.SEND_MONEY_INITIATED, { accountType: account_type });
                    Analytics.track(ACTIONS.SEND_MONEY_INITIATED, {}, { accountType: account_type });
                    break;
                case '/exchange-currency':
                    MoEngageTrackEvent(moengage_events.CURRENCY_EXCHANGE_INITIATED, { accountType: account_type });
                    Analytics.track(ACTIONS.CURRENCY_EXCHANGE_INITIATED, {}, { accountType: account_type });
                    break;
            }
            router.push(path);
        }

        return (
            <Box className={classes['rb-overlay-container']}>
                <Box className={classes['rb-overlay-items-container']}>
                    <Box onClick={() => handleMenuClick('/send-money')} className={classes['rb-overlay-item-container']}>
                        <SendMoney width={24} height={24} color={theme.palette.primary.blue} />
                        <Typography variant='body1' weight='medium' color={theme.palette.primary.navy}>Send money</Typography>
                    </Box>
                    <Box onClick={() => handleMenuClick('/exchange-currency')} className={classes['rb-overlay-item-container']}>
                        <CurrencyExchange width={24} height={24} color={theme.palette.primary.blue} />
                        <Typography variant='body1' weight='medium' color={theme.palette.primary.navy}>Exchange currency</Typography>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <>
            {accountMenuOpen && (
                <MobileMenu
                    handleLogout={handleLogout}
                    setAccountMenuOpen={setAccountMenuOpen} />
            )}
            <Box className={classes['rb-bottom-nav-container']}>
                {isOverlayVisible && <Overlay />}
                <div className={classes['rb-main-action-button-container-back']} />
                <Box className={classes['rb-bottom-nav-menu-container']}>
                    <div onClick={toggleOverlay} className={classes['rb-main-action-button-container']}>
                        <div className={classes['rb-main-action-button']}>
                            {!isOverlayVisible ? <ArrowUpLined width={32} height={32} color={'#FFF'} /> : <CloseIcon width={24} height={24} color={'#FFF'} />}
                        </div>
                    </div>
                    <Box className={classes['rb-menu-items-container']}>
                        <Box className={classes['rb-items-child-container']}>
                            {renderChildItems({ Icon: Home, label: 'Home', action: account_type === 'personal' ? 'dashboard' : 'business-account/dashboard' })}
                            {renderChildItems({ Icon: Recipients, label: 'Recipients', action: 'recipients' })}
                        </Box>
                        <Box className={classes['rb-items-child-container']}>
                            {renderChildItems({ Icon: GiftBox, label: 'Rewards', action: 'rewards' })}
                            {renderChildItems({ Icon: account_type === 'business' ? Business : User, label: 'Account', action: 'account-details' })}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default BottomNavBar